<template>
  <aw-modal
    ref="modalComponent"
    :opened="opened"
    size="sm"
    :closable="closable"
    :modal-title="$awt('aw.common.cart_merge_modal.modal_title')"
    @close-modal="closeModal"
  >
    <template #content>
      <div :class="$style.wrapper" aria-live="assertive">
        <aw-separator margin-top="none" />
        <aw-notification-item
          :notification="{
            type: 'info',
            iconName: 'education-16',
            manualClose: false,
            text: {
              subtitle: $awt('aw.common.cart_merge_modal.description')
            }
          }"
          index="0"
          thick-content
        />
        <aw-separator />
        <div :class="$style.modalFooter">
          <lv-button
            v-for="(option, i) in cartMerge.strategies"
            :key="`merge-option-${i}`"
            loading-id="aw-cart-merge-loading"
            :shrink="screenRange['lg-min']"
            :stretch="screenRange['md-max']"
            :styling="i%2 === 0 ? 'primary-dark' : 'secondary-dark'"
            :class="$style.buttons"
            @click="mergeCart(option)"
          >
            {{ $awt(`aw.common.cart_merge_modal.options.${option}`) }}
          </lv-button>
        </div>
      </div>
    </template>
  </aw-modal>
</template>

<script setup>
  import { useNuxtApp } from 'nuxt/app';
  import { M_CART_MERGE } from '~~/common/config/Modal';
  import { getErrorNotification } from '~~/common/utils/helper';
  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useLoyaltyCardStore } from '~~/shop/stores/loyaltyCard';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import AwModal from '~~/common/components/Common/AwModal.vue';
  import { LvButton } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwSeparator from '~~/common/components/Common/AwSeparator.vue';
  import AwNotificationItem from '~~/common/components/Common/AwNotificationItem.vue';
  import { ref } from 'vue';

  const { $api } = useNuxtApp();

  const apiError = ref(false);
  const modalComponent = ref(null);
  const userInterfaceStore = useUserInterfaceStore();
  const modalStore = useModalStore();

  const modal = computed(() => {
    return modalStore.activeGenericModal;
  });

  const cartMerge = computed(() => {
    return useOrderStore().cartMerge;
  });

  const screenRange = computed(() => {
    return userInterfaceStore.mediaQueries;
  });

  const opened = computed(() => {
    return modal.value.type === M_CART_MERGE;
  });

  const closable = computed(() => {
    return modal.value.item.closable;
  });

  async function mergeCart (option, resolveOption) {
    const orderStore = useOrderStore();
    const loyaltyCardStore = useLoyaltyCardStore();
    try {
      apiError.value = false;
      userInterfaceStore.startLoading({ id: 'aw-cart-merge-loading' });

      let headerParameter = {};
      if (resolveOption) {
        headerParameter = {
          headers: {
            'X-Error-Resolve-Option': resolveOption,
          },
        };
      }

      await $api.post('/orders/current/cart/merge', { strategy: option }, headerParameter);

      await Promise.all([
        orderStore.fetchOrder({
          id: 'current',
          canStartSecondAction: true,
          canRunSimultaneous: true,
        }),
        loyaltyCardStore.fetchExpectedLoyaltyPoints({
          id: 'current',
          type: 'ALL',
        }),
      ]);

      closeModal();
      userInterfaceStore.endLoading({ id: 'aw-cart-merge-loading' });
    } catch (error) {
      apiError.value = getErrorNotification(useNuxtApp(), error);
      // if result equal with precondition required HTTP code: 428 -> set callback method name + resolve header option value
      if (apiError.value?.httpCode === 428) {
        apiError.value.resolveMethodName = 'mergeCart';
        apiError.value.resolveMethodParams = {
          option,
          resolveOption: 'auto',
        };
      }
      // if result equal with precondition failed HTTP code: 412 -> immediate call self with 'force' parameter
      if (apiError.value?.httpCode === 412) {
        await mergeCart(option, 'force');
      }
    }
  }

  function closeModal () {
    userInterfaceStore.setBackdropChildren({
      uuid: modalComponent.value?.uuid,
      value: false,
    });

    modalStore.closeCartMergeModal();
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.buttons {
  text-align: center;
}
</style>
